html.dark {
  background-color: rgb(15, 23, 42);
}

.background {
  display: flow-root;
  background: linear-gradient(#fff9, #fff9), 100% / cover url('./bg-light.webp');
  /* border-radius: 4px; */
  margin: 0 auto 10px;
}

html.dark .background {
  background: linear-gradient(#0007, #0007), 100% / cover url('./bg-light.webp');
}
